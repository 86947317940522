import React from 'react'

const Input = ({id, i, label, type, signlogin, errormessage}) => {
  return (
    <div className='resetpwdbox'>
            <i className={i}></i>
            <input id={id} type={type} {...signlogin} />
            <label htmlFor={id}>{label}</label>
            <span className='error-message'>{errormessage}</span>
    </div>


  )
}

export default Input