import React, { useEffect } from 'react'
import './success.css'
import bg from '../../assets/bg.jpg'
import logo from '../../assets/whitelogo.png'
import { useNavigate } from 'react-router-dom'
import { useSpeechSynthesis } from 'react-speech-kit'


const Successpage = () => {

    const navigate = useNavigate("")
    const {speak} = useSpeechSynthesis()


    

    useEffect(() => {
       setTimeout(()=>{
      
        navigate("/customer-feedback")
        speaKer(1);

       }, 6000)

    });

    const successreturn =()=>{
        navigate("/customer-feedback")
    }

    const speaKer = ()=>{
      speak({
        //    text: "...Dear Customer, Thanks for banking with eaz-way..."
        text: "...Dear Customer, Thank you for participating..."
         });
    }
  return (
    <div onClick={successreturn}>
         <section className="success">
                <img src={bg} alt="bg logo" />
                <div className="soverlay">
                        
                    <h2>Thank you for participating</h2>
                    <br />  
                        <div className='logo-box'>
                            <img src={logo} alt="izwe logo" />
                        </div>
                </div>
            </section>
    </div>
  )
}

export default Successpage