import React from 'react'
import '../dashboard/dashboard.css'
import { useNavigate } from 'react-router-dom'
//import Chart from 'react-apexcharts';
//import axios from '../../api/Axios';

const Individualchart = () => {
    const navigate = useNavigate("")
    //const [chartdata, setchartdata] = useState({})
    const mainDash = () => {
        navigate("/chart-reporting")
    }
    return (
        <div>
            <div className='hed'>
                <i className='fas fa-bar-chart'></i>
                <span>Reports</span>
                <button onClick={mainDash}>Dashboard</button>
            </div>
            <div className='cards'>
                <div className='card c1'>

                </div>
                <div className='card c2'>
                </div>
                <div className='card c3' >
                </div>
                <div className='card c4' >
                </div>
            </div>
        </div>
    )
}

export default Individualchart
