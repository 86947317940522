import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Signup from '../../../views/signup/Signup'
import Branchmanagers from '../../allbranchmanagers/Branchmanagers'
import Chartreporting from '../../chartreporting/Chartreporting'
import Dashboard from '../../dashboard/Dashboard'
import Indivibranch from '../../chartreporting/Individualchart'


const Appcontent = () => {
  return (
    <Routes>
        <Route path='dashboard' element={<Dashboard/>}/>
        <Route path='chart-reporting' element={<Chartreporting/>}/> 
        <Route path='/individual-branches' element={<Indivibranch/>}/>  
        <Route path='all-branch-managers' element={<Branchmanagers/>}/> 
        <Route path='/signup-branch-manager' element={<Signup/>}/> 
    </Routes>
      
   
  )
}

export default Appcontent
