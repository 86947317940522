import React from 'react'
import { useNavigate } from 'react-router-dom'

import {Link} from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import './header.css'


const Header = () => {
const navigate = useNavigate("")
    const logout = ()=>{
      toast.success("Successfully Logout!",{
        position:toast.POSITION.TOP_CENTER
      })
      setTimeout(()=>{
        localStorage.removeItem('izwedata');
        localStorage.clear()
        navigate("/admin-login-izwe")
      }, 3000)
    }
  return (
    <>
    <div className='hd'>
    <Link to="/dashboard" className='header-link'>
          <i className="fas fa-home"></i>
         <span className='nav-link-name'>Home</span>
        <span className='nav-tooltip'>Home</span>
    </Link>
    <Link to="/all-branch-managers" className='header-link'>
          <i className="fas fa-users"></i>
         <span className='nav-link-name'>Managers</span>
        <span className='nav-tooltip'>Managers</span>
    </Link>
    <Link to="/chart-reporting" className='header-link'>
          <i className="fas fa-bar-chart"></i>
         <span className='nav-link-name'>Reports</span>
        <span className='nav-tooltip'>Reports</span>
    </Link>
       <Link onClick={logout} className='header-link'>
       <i className=" fas fa-light fa-user"></i>
       <span className='nav-tooltip'>logout</span>
      </Link>
      <ToastContainer/>
    </div> 

    </>
  )
}

export default Header