import React, { useEffect, useState } from 'react'
import './charts.css'
import Chart from 'react-apexcharts';
import Cards from './Cards';
import axios from '../../api/Axios';
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useNavigate } from 'react-router-dom';


const schema = yup.object({
  branch:yup.string().required("branch field is required"),
  start_date: yup.string().required("Start date field is required!"),
  end_date: yup.string().required("End date field is required!"),

})

const Chartreporting = () => {
  const chartdataurl = "/auth/cust-feedforsevendays"
  //const brnchurl = "/auth/card-data365"
  const individualcharturl = "/auth/single-branch-date"

const navigate = useNavigate("")
  const [chartdata, setchartdata] = useState({})

  // const [temadata, settemadata] = useState({})
  const { handleSubmit, register, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
    branch:'',
    start_date: new Date().toISOString().substring(0, 10),
    end_date: new Date().toISOString().slice(0, 10)
  });
  console.log(errors);

  const [selectdata, setselectdata] = useState(
    {
      branch:'',
      start_date: new Date().toISOString().substring(0, 10),
      end_date: new Date().toISOString().slice(0, 10)
    }
  )


 

 
  useEffect(() => {
    const fetchdata = async () => {
      await axios.post(chartdataurl)
        .then(response =>{
          console.log(response.data.data);
          const {data} = response.data
          //console.log(data.message);
         
          setchartdata(
            {
              series: [{
                name: 'NO',
                data: data.message.map(n=>n.no),

              }, {
                name: 'YES',
                data: data.message.map(y=>y.yes)

              }],
              options: {
                chart: {
                  type: 'bar',
                  height: 350,
                },
                plotOptions: {
                  bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded'
                  },
                },
                dataLabels: {
                  enabled: true,
                  colors: ['#F44336', '#E91E63', '#9C27B0']

                },
                title:{
                  text:'Izwe Customer feedback Bar Chart',
                  align:'center',
                  marginLeft:20,
                  offsetY:20,
                  style:{
                    fontSize:'25px'
                  }
                },
                stroke: {
                  show: true,
                  width: 2,
                  colors: ['transparent']
                },
                xaxis: {
                  categories: data.message.map(b=>b?.branch),
                  title:{
                    text:'Branches'
                  }
                },
                yaxis: {
                  title: {
                    text: 'feedbacks'
                  }
                },
                fill: {
                  opacity: 1
                },
               
                tooltip: {
                  y: {
                    formatter: function (val) {
                      return " " + val + " feedbacks"
                    }
                  }
                }
              },
            
            }
           
          )
        })
        
        .catch(error => {
          console.log(error);
        })
    }
    fetchdata()
  }, [])

  const Indbranch=()=>{
    navigate("/individual-branches")
  }


  const formSubmit = async (selectdata) => {
    //console.log(selectdata);
    const res = await axios.post(individualcharturl, selectdata)
    .then(response=>{
      console.log(response.data.data);
      const {data} = response.data
      //console.log(data.message);
     
      setchartdata(
        {
          series: [{
            name: 'NO',
            data: data.message.map(n=>n.no),
          }, {
            name: 'YES',
            data: data.message.map(y=>y.yes),
          }],
          options: {
            chart: {
              type: 'bar',
              height: 350,
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
              },
              dataLabels: {
                enabled: true,
                colors:['#fff']
  
              },
            },
          
            stroke: {
              show: true,
              width: 2,
              colors: ['transparent']
            },
            title:{
              text:'Izwe Customer feedback Bar Chart',
              align:'center',
              marginLeft:20,
              offsetY:20,
              style:{
                fontSize:'25px'
              }
            },
    
            xaxis: {
              categories: data.message.map(b=>b?.branch),
              title:{
                text:'Branches'
              }
            },
            yaxis: {
              title: {
                text: 'feedbacks'
              }
            },
            fill: {
              opacity: 1,

            },  
            tooltip: {
              y: {
                formatter: function (val) {
                  return " " + val + " feedbacks"
                }
              }
            }
          },
        
        }
       
      )
    })
    
    .catch(error => {
      console.log(error);
    })
    
    return res
  }


  return (
    <div>
      <br />
      <div className='c'>
      <div className='hed'>
        <i className='fas fa-bar-chart'></i>
        <span>Reports</span>
        <button onClick={Indbranch}>Individual branches</button>
      </div>
        <Cards />
        <div className='period'>
          <form onSubmit={handleSubmit(formSubmit)}>
            <div className='str'>
            <select name="branch" id="branch" style={{marginLeft:"20px",textAlign:"center"}} {...register('branch', { required: true })} onChange={e => setselectdata(e.target.value)}>
              <option value="">-select branch-</option>
              <option value="Tema">Tema</option>
                <option value="Adum">Adum</option>
                <option value="Cape Coast">Cape Coast</option>
                <option value="Koforidua">Koforidua</option>
                <option value="Ho">Ho</option>
                <option value="Kasoa">Kasoa</option>
                <option value="Tamale">Tamale</option>
                <option value="Takoradi">Takoradi</option>
                <option value="RRC">RRC</option>
                <option value="Service Center">Service Center</option>
                <option value="Sunyani">Sunyani</option>
                <option value="Suame">Suame</option>
            </select>
              <span>Start</span>
              <input style={{textAlign:"center"}} name='start_date' type="date" {...register('start_date', { required: true })} onChange={e => setselectdata(e.target.value)} />

              <h4 className='danger'>
                <br />
                {errors.start_date?.type === "required" && "Start date field is required!"}
              </h4>
            </div>
            <div className='end'>
              <span>End</span>
              <input style={{textAlign:"center"}} name='end_date' type="date" {...register('end_date', { required: true })} onChange={e => setselectdata(e.target.value)} value={selectdata.end_date} />
              <h4 className='danger'>
                <br />
                {errors.end_date?.type === "required" && "End date field is required!"}
              </h4>
            </div>
            <button type='submit'>Submit date</button>
          </form>
        </div>

      </div>
      <div className='bggraph'>
        {chartdata && chartdata.series &&(
      <Chart type='bar' options={chartdata.options} series={chartdata.series} height={300} />
        )}
      </div>
    </div>
  )
}

export default Chartreporting
