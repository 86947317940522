import React, { useEffect, useState } from 'react'
import './adminlogin.css'
import Input  from '../../views/Input'
import goglogo from '../../assets/ilogo.png'
import { Link, useNavigate } from 'react-router-dom';
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import axios from '../../api/Axios'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { HashLoader } from 'react-spinners';
// import AuthContext from '../../context/AuthProvider';


const schema = yup.object({
  email:yup.string().required("Email field is required!").email(),
  password:yup.string().min(6,"Password must be at least 6 Characters").required(),

  })

const Adminlogin = () => {
  const adminloginurl = '/auth/admin-login'

  // const {setAuth} = useContext(AuthContext)


    // const [err, setErr] = useState(false);
    const [loading, setloading] = useState(false)
    const navigate = useNavigate("")
  const {handleSubmit, register, formState:{errors}} = useForm({
    resolver:yupResolver(schema),
    email:'',
    password:''
  });
  //console.log(errors);

  useEffect(()=>{
    setloading(true)
    setTimeout(()=>{
      setloading(false)
    }, 3000)
  },[])
 
    const formSubmit= async({email, password})=>{
            const res= await axios.post(adminloginurl, {email, password})
            .then((response)=>{
              //console.log(response);
              const {  data } = response.data;
              if(data.success) {
                localStorage.setItem('izwedata', JSON.stringify(response.data));
                toast.success(data.message,{
                    className: 'toast-message',
                    position: toast.POSITION.TOP_CENTER

                })
                setTimeout(()=>{
                  navigate("/dashboard")
                }, 5000)
                return;
              }

              if(data){
                Error(data)
                return;
              }
            
            })
            .catch(error=>{
              console.log(error);
            })

            return res
    }
           
       
    
  return (
    <div className='bgadminlogin'>
      <ToastContainer/>
      {
        loading?
        <span>Login Loading...<HashLoader className='clips' color="#120b4b" loading={loading} size={80}  /></span>
        :
        <div className='container'>
        <br />
          <h2>Admin Login</h2>
          <div className='code-container'>   

      <form onSubmit={handleSubmit(formSubmit)}>
          <div className='resetpwdbox'>
          <Input name='email'  type="text" id='email' signlogin={{...register('email')}} label='Enter Email' 
          errormessage={errors.email?.message} i='fas fa-thin fa-envelope' />
        </div>
        <div className='resetpwdbox'>
        <Input name='password'  type="password" id='password' signlogin={{...register('password')}} label='Enter Password' 
          errormessage={errors.password?.message} i='fas fa-thin fa-lock' />
        </div>
          
          <div className='otpbtnclass'>
              <button type='submit' className='btnotp btn-primary'>Login</button>
          </div>
          {/* {err && <span>Something went wrong</span>} */}
          <p>Reset | <Link to="">Reset Password</Link></p>
          <br />
          <p>You do have an account? <Link to="/admin-signup-izwe">Register</Link></p>

      </form>
  </div>
  <img src={goglogo} alt="gog logo" />

      </div>
      }
       

    </div>
  )
}

export default Adminlogin