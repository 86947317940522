import { yupResolver } from '@hookform/resolvers/yup'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import axios from '../../api/Axios'
import yesway from '../../assets/yesway.png'
import noway from '../../assets/noway.png'
import logo from '../../assets/ilogo.png'

import 'react-toastify/dist/ReactToastify.css';
import * as yup from 'yup'
import './userfeed.css'

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const schema = yup.object({
    phone_number: yup.string().matches(phoneRegExp, 'Phone number is not valid').required("Please enter your phone number!"),
    comment: yup.string().required("Feedback option is required!")
})
const UserFeed = () => {
    const feedbackurl = '/branch/cust-feedback'
    const navigate = useNavigate("")

    const { register, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        phone_number: "",
        comment: "",
    });
    //console.log(errors);
    toast.error(errors)

    const [data, setdata] = useState({
        phone_number: "",
        comment: ""
    })

    const token = JSON.parse(localStorage.getItem('izwedata'));
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token.access_token}`
    }; // auth header with bearer token

    const Feedback = async (e, comment) => {
        e.preventDefault();
        //console.log(data);
        const newData = {
            phone_number: data.phone_number,
            comment
        }
        const res = await axios.post(feedbackurl, newData, { headers })
            .then(response => {
                //console.log(response);
                const { data } = response.data;
                if (data.success === true) {
                    localStorage.getItem('izwedata', JSON.stringify(response.data.access_token));
                    toast.success(data.message, {
                        position: toast.POSITION.TOP_CENTER
                    })
                    setTimeout(() => {
                        navigate("/feedback-success")
                    }, 2000)
                }
                if (!data.success === true) {
                    //Error()
                    toast.error(data.message + " \n Please check number and click again to be sure...", {
                        position: toast.POSITION.TOP_CENTER
                    })
                }
                return
            })
            .catch(error => {
                console.log(error);
                // setrequesterror(error.message)
                toast.error(error.message, {
                    position: toast.POSITION.TOP_CENTER
                })
            })
        return res
    };
    const logout = () => {
        toast.success("Successfully Logout!", {
            position: toast.POSITION.TOP_CENTER
        })
        setTimeout(() => {
            localStorage.removeItem('izwedata');
            localStorage.clear()
            navigate("/")
        }, 2000)
    }
    return (
        <>

            <div className='bg'>
                <ToastContainer />
                <div className='feedtainer'>
                <Link style={{ color: "#000", float: "right", fontWeight: "bolder" }} onClick={logout}>Logout</Link>
                    {/* <h2>Happy with service just received?</h2> */}
                    <div className='feed-container'>
                        <form>
                            <div className='feedbackbox'>
                                <label htmlFor="">Please enter phone number</label>
                                <input name='phone_number' {...register('phone_number', { required: true })} type="number" id='phone_number' onChange={(e) => { setdata({ ...data, phone_number: e.target.value }) }} placeholder="Enter your phone number here" />
                                {/* <span className='danger'>
                                    {errors.phone_number?.message}
                                </span> */}
                            </div>
                            <h2>Happy with service just received?</h2>
                            <div className='otpbtnc'>
                                <div>
                                    <button className='btncomment' type='submit' name="comment" id='comment' value="Yes" onClick={(e) => Feedback(e, 'Yes')}>
                                        <div className='fdimg-container'>
                                            <img src={yesway} alt="no logo" />
                                        </div>
                                        <span>YES</span>
                                    </button>
                                </div>
                                <div>
                                    <button className='btncomment' type='submit' name="comment" id='comment' value="No" onClick={(e) => Feedback(e, 'No')}>
                                        <div className='fdimg-container'>
                                            <img src={noway} alt="no logo" />
                                        </div>
                                        <span>NO</span>
                                    </button>
                                </div>
                            </div>

                            {/* {err && <span>Something went wrong</span>} */}
                        </form>
                    </div>
                    <img src={logo} alt="gog logo" />

                </div>

            </div>

        </>
    )
}

export default UserFeed
