import React, { useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import axios from '../../api/Axios';
import '../dashboard/dashboard.css'
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from 'react-spinners'


const Branchmanagers = () => {
  const allmanagersurl = "/auth/all-branch"
  const delmanagersurl = "/auth/delete-user/"

  const [columns, setcolumns] = useState([])
  const [records, setrecords] = useState([])
  const [loading, setloading] = useState(false)

  const navigate = useNavigate("")

  useEffect(() => {
    setloading(true)
    setTimeout(()=>{
      getallmanagers();
      setloading(false)
    }, 3000)
  }, [])

  const getallmanagers=()=>{
    axios.post(allmanagersurl).then(response => {
      //console.log(response.data.data.message[0]);
       setcolumns(Object.keys(response.data.data.message[0]))
      setrecords(response.data.data.message)
    })
  }

  const  resgisterbranch =()=>{
    // toast.success("You are about registering branch & manager",{
    //   position:toast.POSITION.TOP_CENTER
    // })
    // setTimeout(()=>{
    navigate("/signup-branch-manager")
    // }, 1000)
  }


  const Deleteuser= async (id)=>{
    const res = await axios.delete(delmanagersurl+id)
    .then(response=>{
      //console.log(response);
      toast.success(response.data.data.message,{
        position:toast.POSITION.TOP_CENTER
      })
      getallmanagers();
      
    }).catch(error=>{
      //console.log(error);
      toast.error(error.message,{
        position:toast.POSITION.TOP_CENTER
      })
    })
    return res
  }

  return (
    <div>
      <br />
      <div className='hed'>
        <ToastContainer/>
        <i className='fas fa-users'></i>
        <span>Managers</span>
        <button onClick={resgisterbranch}>Register branch</button>
      </div>
     {
      loading?
      
      <span>Loading...<BeatLoader className='clips' color="#120b4b" loading={loading} size={20}  /></span>
      :
      <table id="customers">
      <thead>
        <tr className='t-head'>
          {
            columns.map((b, i) => (
              <th key={i}>{b}</th>
            ))
          }
          <th>Action</th>
        </tr>
      </thead>

      <tbody>

        {
          records.map((data, i) => (
            <tr key={i}>
              <td>{data.id}</td>
              <td>{data.email}</td>
              <td>{data.branch}</td>
              <td><button onClick={()=>Deleteuser(data.id)}>delete</button></td>
            </tr>
          ))
        }
      </tbody>
    </table>
     }
    </div>
  )
}

export default Branchmanagers
