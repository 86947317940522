import React, { useEffect, useState, useRef } from 'react'
import axios from '../../api/Axios';
import './dashboard.css'
import { BeatLoader } from 'react-spinners'
import { useReactToPrint } from 'react-to-print';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { BrowserRouter as Router, Link } from 'react-router-dom';

const Dashboard = () => {
  const allfeedbackurl = "/auth/cust-allfeed"
  const [columns, setcolumns] = useState([]);
  const [records, setrecords] = useState([]);
  const [loading, setloading] = useState(false);
  const componentPDF = useRef();


  useEffect(() => {
    setloading(true);
    setTimeout(()=>{
      allcomments();
      setloading(false)
    }, 3000)
   
  }, [])

  const allcomments = async ()=>{
    await axios.post(allfeedbackurl).then(response => {
      //console.log(response.data.data.message[0]);
       setcolumns(Object.keys(response.data.data.message[0]))
      setrecords(response.data.data.message)
    })
  }

  const generatePDF = useReactToPrint({
    content:()=>componentPDF.current,
    documentTitle:"Weekly_Customer_feedback_report",
    onAfterPrint:()=>toast.success("Data has been saved!")
  })

  return (
    <div>
      <br/>
      <div className='hed'>
        <ToastContainer/>
        <i className='fas fa-home'></i>
        <span>Home</span>
        <button style={{float:'right', margin:'3px'}} onClick={generatePDF}>Generate report</button>
      </div>

     {
      loading?
      <span>Loading...<BeatLoader className='clips' color="#120b4b" loading={loading} size={20}  /></span>
      :
      <div ref={componentPDF} style={{width:"100%"}}>
      <table id="customers">
      <thead>
        <tr className='t-head'>
          {
            columns.map((b, i) => (
               <th key={i}>{b}</th>
            ))
          }
        </tr>
      </thead>
      <tbody>

        {
          records.map((data, i) => (
            <tr key={i}>
              <td>{data.id}</td>
              <td>{data.branch}</td>
              <td>{data.phone_number}</td>
              <td style={{fontWeight:"bold",fontSize:"18px" }}>{data.comment}</td>
              <td>{data.date}</td>

            </tr>
          ))
        }
      </tbody>
    </table>
    </div>
     }
    </div>
  )
}

export default Dashboard
