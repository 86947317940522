import './App.css';
import Login from './views/login/Login';
import Adminlogin from './admin/login/Adminlogin';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Adminsignup from './admin/signup/Adminsignup';
import AppLayout from './admin/components/layout/AppLayout';
import Userfeedback from './views/userfeedback/Userfeedback';
import Successpage from './views/userfeedback/Successpage';
import UserFeed from './views/userfeedback/UserFeed';

function App() {
  return (
    <Router>
        <Routes>
            <Route path='' element={<Login/>} />
            <Route path="feedback-success" element={<Successpage/>} /> 
            <Route path='admin-signup-izwe' exact element={<Adminsignup/>} />
            <Route path='admin-login-izwe' exact element={<Adminlogin/>}/>
             <Route path='user-feedback' exact element={<Userfeedback/>} />
             <Route path='customer-feedback' exact element={<UserFeed/>} />
            <Route path="*" name="Home" element={<AppLayout/>} />   
        </Routes>
    </Router>
  );
}

export default App;
