import React, {useState} from 'react'
import bg from '../../assets/bg.jpg'
import './userfeedback.css'
import yesway from '../../assets/yesway.png'
import noway from '../../assets/noway.png'
import logo from '../../assets/ilogo.png'
import { useNavigate, Link } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

import axios from '../../api/Axios'


const schema = yup.object({
    phone_number:yup.string().required("Please enter your phone number!"),
    comment: yup.string().required("Feedback field is required!"),
  })
  
const Userfeedback = () => {
    const feedbackurl = '/branch/cust-feedback'
const navigate = useNavigate("")

const {register, formState:{errors}} = useForm({
    resolver:yupResolver(schema),
    phone_number:"",
    comment:"",
  });
  console.log(errors);

  const [data, setdata] = useState({
    phone_number: "",
    comment: ""
})
// const [requesterror, setrequesterror] = useState()

const token = JSON.parse(localStorage.getItem('izwedata'));
const headers = { 
 'Content-Type': 'application/json',
 'Authorization': `Bearer ${token.access_token}`
 }; // auth header with bearer token

// axios.interceptors.request.use(
//     config=>{
//         config.headers.authorization= `Bearer ${token.access_token}`
//         return config
//     },
//     error=>{
//         return Promise.reject(error)
//     }
     
//)
// const handlechange=(e)=>{
//   // const name = e.target.name;
// // const value = e.target.value;
// // setdata({...data, [name]: value})
// }

    const Feedback = async (e)=>{
         e.preventDefault();
         //console.log(data);
        const res = await axios.post(feedbackurl, data, {headers})
        .then(response=>{
            //console.log(response);
            const {  data } = response.data;            
            if(data.success===true){
                localStorage.getItem('izwedata', JSON.stringify(response.data.access_token));
                toast.success(data.message,{
                    position:toast.POSITION.TOP_CENTER
                })
                setTimeout(()=>{    
                    navigate("/feedback-success")
                  }, 2000)
                } 
                if(!data.success===true){
                  //Error()
                  toast.error(data.message + " \n Please check number and click again to be sure...",{
                    position: toast.POSITION.TOP_CENTER
                  })        
            }
            return 
        })
        .catch(error=>{
            console.log(error);
            // setrequesterror(error.message)
            toast.error(error.message,{
                position:toast.POSITION.TOP_CENTER
            })
        })
        return res 
    };

    const logout =()=>{
        toast.success("Successfully Logout!",{
            position:toast.POSITION.TOP_CENTER
          })
          setTimeout(()=>{
            localStorage.removeItem('izwedata');
            localStorage.clear()
            navigate("/")
          }, 2000)   
    }

    return (
        <div>
            <section className="showcase">
            <ToastContainer/>
                <img src={bg} alt="bg logo" />
                <div className="overlay">
                <Link style={{color:"#fff", float:"right",fontWeight:"bolder" }} onClick={logout}>Logout</Link>
                    <br /><br /><br />
                    <div className="cd">
                    <h2>Happy with service just received?</h2>
                        <form>
                            <br />
                            <div className="userbox">
                            <label htmlFor="">Please enter your phone number</label>
                            <input name='phone_number' {...register('phone_number', {required: true })}  type="number" id='phone_number' onChange={(e)=> {setdata({...data, phone_number:e.target.value})}} placeholder="Enter your phone number here" />
                            </div>

                            <div className='radiobox'>
                                <button className='btnrad' type='submit' name="comment" id='comment' value="Yes" onClick={Feedback}>
                                <div className="radiogroup">
                                    <div className='img-container'>
                                        <img src={yesway} alt="no logo" />
                                    </div>                      
                                    <span>YES</span>
                                </div>
                                </button>
                                <button className='btnrad' type='submit' name="comment" id='comment' value="No"  onClick={(e) => Feedback(e,'No')}>
                                <div className="radiogroup">
                                    <div className='img-container'>
                                        <img src={noway} alt="no logo" />
                                    </div>
                                    <span>NO</span>
                                </div>
                                </button>
                            </div>
                            <br />
                        </form>
                        <div className="btm-img">
                        <img src={logo} alt="izwe logo" />
                        </div>
                </div>
                </div>
            </section>
        </div>
    )
}

export default Userfeedback