import React from 'react'
import '../login/adminlogin.css'
import Input  from '../../views/Input'
import goglogo from '../../assets/ilogo.png'
import { Link, useNavigate } from 'react-router-dom';
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
 import {toast, ToastContainer } from 'react-toastify';
// import AuthContext from '../../context/AuthProvider';
import axios from '../../api/Axios';
import {Error} from '../../utils/util'

const schema = yup.object({
  email:yup.string().required("Email field is required!").email(),
  password:yup.string().min(6,"Password must be at least 6 Characters").required(),

  })

const Adminsignup = () => {
  const adminsignupurl = '/auth/admin-signup'

  // const {setAuth} = useContext(AuthContext)
    // const [err, setErr] = useState(false);
    const navigate = useNavigate("")
  const {handleSubmit, register, formState:{errors}} = useForm({
    resolver:yupResolver(schema),
    email:'',
    password:''
  });
  console.log(errors);
 
    const formSubmit= async({email, password})=>{
            const res= await axios.post(adminsignupurl, {email, password})
            .then((response)=>{
              //console.log(response);
              const {  data } = response.data;
              if(data.success) {
                localStorage.setItem('izwedata', JSON.stringify(response.data));
                toast.success(response.data.data.message,{
                    className: 'toast-message',
                    position: toast.POSITION.TOP_CENTER

                })
                setTimeout(()=>{
                  navigate("/admin-login-izwe")
                }, 4000)
                return;
              }

              if(data){
                Error(data)
                return;
              }
            
            })
            .catch(error=>{
              console.log(error);
            })
            return res             
    }
           
  return (
    <div className='bgadminlogin'>
          <br />
        <div className='container'>
          <h4>Customer Satisfaction,</h4>
            <h2>Create Account</h2>
            <div className='code-container'>   
        <form onSubmit={handleSubmit(formSubmit)}>
            
            <div className='resetpwdbox'>
            <Input name='email'  type="email" id='email' signlogin={{...register('email')}} label='Enter Email' 
            errormessage={errors.email?.message} i='fas fa-thin fa-envelope' />
          </div>
        
          <div className='resetpwdbox'>
          <Input name='password'  type="password" id='password' signlogin={{...register('password')}} label='Enter Password' 
            errormessage={errors.password?.message} i='fas fa-thin fa-lock' />
          </div>
            
            <div className='otpbtnclass'>
                <button type='submit' className='btnotp btn-primary' >Register</button>
            </div>
            {/* {err && <span>Something went wrong</span>} */}
            <p>Reset | <Link to="/">Reset Password</Link></p>
            <br />
            <p>You do have an account? <Link to="/admin-login-izwe">Login</Link></p>
        </form>
    </div>
       <img src={goglogo} alt="gog logo" />
        </div>
        <ToastContainer/>
    </div>
  )
}

export default Adminsignup