import {toast} from 'react-toastify';

export const Error = (responseData) => {
    const { data } = responseData;
    if(data) {
        let keys = Object.keys(data);
        keys.forEach((key) => {
            console.log(data[key])
            data[key].forEach((message) => toast.error(message))
        })
    }

}

  // const series = [
  //   {
  //     name: "Temperature in Fahrenheit", //will be displayed on the y-axis
  //     data: [43, 53, 50, 57, 40, 60, 20, 25, 30]
  //   }
  // ];
  // const options = {
  //   chart: {
  //     id: "simple-bar"
  //   },
  //   xaxis: {
  //     categories: [1, 2, 3, 4, 5, 6, 7, 8, 9] //will be displayed on the x-asis
  //   }
  // };
  