import React from 'react'
import './signup.css'
import Input from '../Input'
 import goglogo from '../../assets/ilogo.png'
import { useNavigate, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import axios from '../../api/Axios'
import { toast, ToastContainer } from 'react-toastify';
// import AuthContext from '../../context/AuthProvider';
// import Select from 'react-select'

const schema = yup.object({
  branch: yup.string().required("Branch field is required!"),
  email: yup.string().required("Email field is required!").email(),
  password: yup.string().min(6, "Password must be at least 6 Characters").required(),
})
const Signup = () => {
  const bsignupurl = '/auth/branch-signup'
  const navigate = useNavigate("")
  const { handleSubmit, register, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
    branch: '',
    email: '',
    password: ''
  });
  //console.log(errors);
  toast.error(errors)

  const formSubmit = async ({ branch, email, password }) => {
    const res = await axios.post(bsignupurl, { branch, email, password })
      .then((response) => {
        //console.log(response.data);
        const {access_token, message} = response.data;
        if(access_token){
          toast.success(message,{
            position:toast.POSITION.TOP_CENTER
          })
          navigate("/all-branch-managers")   
         
        }

        if(!access_token){
          toast.error("Branch or Email already exists...\n Please check list of managers to confirm",{
            position:toast.POSITION.TOP_CENTER
          })
          // &&
          // toast.error(data.branch[0],{
          //   position:toast.POSITION.TOP_CENTER
          // })
         
        }
      
      })
      .catch(error => {
        //console.log(error);
        toast.error(error.message, {
          position: toast.POSITION.TOP_CENTER
        })

      })

    return res

  }

  const backallMangers = ()=>{
    setTimeout(()=>{
      navigate("/all-branch-managers")
    },1000)
  }

  return (
    <>
    <div className='hed' style={{position:"fixed"}}>
        <i className='fas fa-users'></i>
        <span>Register Branch</span>
        </div>
     <div className='bgsignup'>
       
          <ToastContainer />
      <div className='container'>
        {/* <h4>Customer Satisfaction</h4> */}
        <h2>Register Account</h2>
        <div className='code-container'>
          <form onSubmit={handleSubmit(formSubmit)}>

            <div className='resetpwdbox'>
              <select name='branch' id='branch' {...register('branch', { required: true })}>
                <option value="">--Select branch--</option>
                <option value="Tema">Tema</option>
                <option value="Adum">Adum</option>
                <option value="Cape Coast">Cape Coast</option>
                <option value="Koforidua">Koforidua</option>
                <option value="Ho">Ho</option>
                <option value="Kasoa">Kasoa</option>
                <option value="Tamale">Tamale</option>
                <option value="Takoradi">Takoradi</option>
                <option value="RRC">RRC</option>
                <option value="Service Center">Service Center</option>
                <option value="Sunyani">Sunyani</option>
                <option value="Suame">Suame</option>
              </select>
              <span className='danger'>
                {errors.branch?.type === "required" && "Select field is required!"}
              </span>
            </div>
            <div className='resetpwdbox'>
              <Input name='email' type="text" id='email' signlogin={{ ...register('email') }} label='Enter Email'
                errormessage={errors.email?.message} i='fas fa-thin fa-envelope' />
            </div>

            <div className='resetpwdbox'>
              <Input name='password' type="password" id='password' signlogin={{ ...register('password') }} label='Enter Password'
                errormessage={errors.password?.message} i='fas fa-thin fa-lock' />
            </div>

            <div className='otpbtnclas'>
              <button type='submit' className='btnotp btn-primary'>Register</button>
            </div>
             <p>Back? | <Link onClick={backallMangers}>Back</Link></p> 

            {/* {err && <span>Something went wrong</span>} */}
          </form>
        </div>
        <img src={goglogo} alt="gog logo" />

      </div>

    </div>
    </>
  )
}

export default Signup