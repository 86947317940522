import React, { useEffect, useState } from 'react'
import './login.css'
import Input from '../Input'
import goglogo from '../../assets/ilogo.png'
import { useNavigate, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Error } from '../../utils/util'
import axios from '../../api/Axios';
import { HashLoader } from 'react-spinners'

const schema = yup.object({
  email: yup.string().required("Email field is required!").email(),
  password: yup.string().min(6, "Password must be at least 6 Characters").required()
})

const Login = () => {
  const bloginurl = '/branch/branch-login'
  // const [err, setErr] = useState(false);
  const [loading, setloading] = useState(false)
  const navigate = useNavigate("")
  const { handleSubmit, register, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
    email: '',
    password: ''
  });
  //console.log(errors);

  useEffect(()=>{
    setloading(true)
    setTimeout(()=>{
      setloading(false)
    }, 3000)
  }, [])

  const formSubmit = async ({ branch, email, password }) => {
    const res = await axios.post(bloginurl, { branch, email, password })
      .then((response) => {
        //console.log(response.data);
        const { access_token, message, data } = response.data;
        if (access_token) {
          localStorage.setItem('izwedata', JSON.stringify(response.data));
          toast.success(message, {
            className: 'toast-message',
            position: toast.POSITION.TOP_CENTER
          });
          setTimeout(() => {
            navigate("/customer-feedback")
          }, 2000)
          return;
        }
        if (data) {
          Error(data)
          toast.error(data.message,{
            position: toast.POSITION.TOP_CENTER
          })
          return;
        }

      })
      .catch(error => {
        //console.log(error);
        toast.error(error.message+ " \n Check your internet connectivity and try again",{
          position: toast.POSITION.TOP_CENTER
        })
      })
    return res
  }
  return (
    <div className='bglogin'>
            <ToastContainer />
            {
              loading?
              <span>Login Loading...<HashLoader className='clips' color="#120b4b" loading={loading} size={80}  /></span>
              :
              <div className='container'>
        <h4>Customer Satisfaction,</h4>
        <h2> Manager Login</h2>

        <div className='code-container'>
          <form onSubmit={handleSubmit(formSubmit)}>         
            <div className='resetpwdbox'>
              <Input name='email' type="text" id='email' signlogin={{ ...register('email') }} label='Enter Email'
                errormessage={errors.email?.message} i='fas fa-thin fa-envelope' />
            </div>
            <div className='resetpwdbox'>
              <Input name='password' type="password" id='password' signlogin={{ ...register('password') }} label='Enter Password'
                errormessage={errors.password?.message} i='fas fa-thin fa-lock' />
            </div>
            <div className='otpbtnclass'>
              <button type='submit' className='btnotp btn-primary'>Login</button>
            </div>
            <p>Reset | <Link>Reset Password</Link></p>

            {/* {err && <span>Something went wrong</span>} */}
          </form>
        </div>
        <img src={goglogo} alt="gog logo" />

      </div>
            }
      

    </div>
  )
}

export default Login