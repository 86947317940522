import axios from '../../api/Axios'
import React, { useEffect, useState } from 'react'
import './card.css'

const Cards = () => {
  const brnchurl = "/auth/card-data365"

  const[brnch, setbrnch] = useState([])
  useEffect(()=>{
    const brnh = async()=>{
      await axios.post(brnchurl).then(response=>{
        // console.log(response);
        //const {data} = response.data.data;
        setbrnch(response.data.data.message[0])

      })
    }
    brnh();
  },[])
  return (
    <div>
      <div className='cards'>
        <div className='card c1'>
        <i className='fas fa-users'></i>
            <h3>Total Branches</h3>
                <span>{brnch.branch}</span>
        </div>
        <div className='card c2'>
        <i className='fas fa-thin fa-thumbs-up'></i>
            <h3>Number of YESs</h3>
                <span>{brnch.yes}</span>
        </div>
        <div className='card c3' >
        <i className='fas fa-thin fa-thumbs-down'></i>
            <h3>Number of NOs</h3>
                <span>{brnch.no}</span>
        </div>
        <div className='card c4' >
<i class="fa fa-comments" aria-hidden="true"></i>
            <h3>Total feedbacks</h3>
                <span>{brnch.comment}</span>
        </div>
      </div>
    </div>
  )
}

export default Cards
